import { Avatar } from '@material-ui/core';

export default function BusinessOwnerDetail() {
  return (
    <section className="flex-1">
      <div className="flex gap-2 mb-5">
        <Avatar className="bg-primary-extraLight">
          <p className="text-sm font-bold text-black">J</p>
        </Avatar>

        <div className="mr-20">
          <p className="text-base font-bold text-secondary">John Doe</p>
          <p className="text-sm">DNT@email.com</p>
          <p className="text-sm">555-555-5555</p>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Mailing Address</p>

            <p className="text-sm font-medium text-primary">
              1560 Jaudon RD, Elberton, GA 30635
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Terminal Address</p>

            <p className="text-sm font-medium text-primary">
              1560 Jaudon RD, Elberton, GA 30635
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs text-text-hint">Producer Contact</p>

            <p className="text-sm font-medium text-primary">
              John Doe, DNT@email.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
