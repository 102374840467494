import { useSearchParams } from 'react-router-dom';

import { formatNumber, Show, TableV8 } from '@nirvana/ui-kit';

import { coverages } from '../constants/coverage';
import { mockVehicleData, vehicleColumns } from '../constants/vehicle';
import { driverColumns, mockDriverData } from '../constants/driver';

export default function PolicyTab() {
  const [searchParams] = useSearchParams();

  return (
    <Show when={searchParams.get('tab') === 'policy'}>
      <h2 className="mb-5 text-xl font-medium">Coverage Lines</h2>

      <div className="grid grid-cols-2 gap-6 mb-5">
        {coverages.map(({ title, description, limit, deductible }) => (
          <div
            key={title}
            className="grid grid-cols-2 gap-4 p-4 bg-white rounded-lg shadow-lg"
          >
            <div className="min-h-[9rem]">
              <p className="mb-1 font-bold">{title}</p>
              <p className="text-xs text-text-hint">{description}</p>
            </div>

            <div>
              <div className="mb-3">
                <p className="mb-1 text-xs text-text-hint">Limit</p>
                <p className="font-medium whitespace-pre-wrap">{limit}</p>
              </div>

              <div>
                <p className="mb-1 text-xs text-text-hint">Deductible</p>
                <p className="font-medium">${formatNumber(deductible)}</p>
              </div>
            </div>

            {/* <p className="col-span-2 font-semibold cursor-pointer text-primary-main">
              View Ancillary Coverages
            </p> */}
          </div>
        ))}
      </div>

      <h2 className="mb-5 text-xl font-medium">Vehicles</h2>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={vehicleColumns} data={mockVehicleData} />
      </div>

      <h2 className="mb-5 text-xl font-medium">Drivers</h2>

      <div className="mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={driverColumns} data={mockDriverData} />
      </div>
    </Show>
  );
}
