import { formatNumber } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';

type VehicleData = {
  VIN: string;
  year: number;
  make: string;
  vehicleClass: string;
  owner: string;
  addedDate: string;
  statedValue: number;
};

export const mockVehicleData: VehicleData[] = [
  {
    VIN: '1AQTDSSSSMNL32142',
    year: 2019,
    make: 'Freightliner Coronado',
    vehicleClass: 'Tractor, Trailer Tractor >33,000',
    owner: 'Owner-Operator',
    addedDate: '01/01/2024',
    statedValue: 70000,
  },
  {
    VIN: '1AQTDSSSSMNL32142',
    year: 2017,
    make: 'Freightliner Coronado',
    vehicleClass: 'Tractor, Trailer Tractor >33,000',
    owner: 'Owned',
    addedDate: '01/01/2024',
    statedValue: 70000,
  },
  {
    VIN: '1AQTDSSSSMNL32142',
    year: 2017,
    make: 'Freightliner Coronado',
    vehicleClass: 'Tractor, Trailer Tractor >33,000',
    owner: 'Owned',
    addedDate: '01/01/2024',
    statedValue: 70000,
  },
  {
    VIN: '1AQTDSSSSMNL32142',
    year: 2023,
    make: 'Kalyn Flatbed',
    vehicleClass: 'Trailer, Flatbed, >33,000',
    owner: 'Owned',
    addedDate: '01/01/2024',
    statedValue: 70000,
  },
];

const columnHelper = createColumnHelper<VehicleData>();

export const vehicleColumns = [
  columnHelper.accessor('VIN', {
    header: 'Description',
    size: 500,
    cell: ({ getValue, row }) => {
      const { year, make, vehicleClass } = row.original;

      return (
        <div>
          <p className="font-bold mb-0.5">{getValue()}</p>
          <p className="text-xs">
            {year} {make} {vehicleClass}
          </p>
        </div>
      );
    },
  }),
  columnHelper.accessor('addedDate', {
    header: 'Added Date',
  }),
  columnHelper.accessor('statedValue', {
    header: 'Stated Value',
    cell: ({ getValue }) => `$${formatNumber(getValue())}`,
  }),
];
