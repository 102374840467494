import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  CoverageType,
  FileDestinationGroup,
  FileMetadata,
  FileType,
  TargetPrice,
  TelematicsInfo,
} from '@nirvana/api/quoting';
import {
  AttachmentView,
  CheckboxRow,
  Chip,
  ITheme,
  InputNumeric,
  Switch,
} from '@nirvana/ui-kit';
import * as React from 'react';
import {
  Controller,
  FieldErrors,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useClipboard } from 'use-clipboard-copy';

import Button, { FileUpload } from 'src/components/button';
import { SummaryView } from 'src/components/table';
import { useDispatch } from 'src/redux';

import IconEdit from 'src/assets/icons/edit.svg';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import { application as ApplicationHelper } from 'src/helpers';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

import { ADDITIONAL_INFO_PAGE_VIEW } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import {
  cancelApplicationFilesUpload,
  downloadApplicationFile,
  fetchDOTInformation,
  removeApplicationFileUpload,
  resetApplicationFileUpload,
  retryApplicationFilesUpload,
  uploadApplicationFiles,
} from '../../../actions';
import { FileUploadProgress, applicationSelector } from '../../../slices';
import ConnectionDrawer from '../connectionDrawer';
import DriversListDialog from '../driversListUpload';
import ConnectEld from './connectELD';

const ContentMaxWidth = 380;

// Used in renewals as well
export const useStyles = makeStyles((theme: ITheme) => ({
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'normal',
  },
  checkbox: {
    marginBottom: theme.spacing(1.25),
    marginLeft: 0,
    marginRight: 0,
  },
  formControlInput: {
    width: ContentMaxWidth,
  },
  formControlTextarea: {
    width: ContentMaxWidth,
  },
  summaryContainer: {
    width: ContentMaxWidth,
  },
  telematicsInfoCard: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    width: theme.typography.pxToRem(380),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export interface IAdditionalInfoProps {
  applicationId: string;
  control: any;
  errors: any;
  setValue: any;
  watch: any;
  telematicsInfo: TelematicsInfo;
}

export const getHelperText = (
  fieldName: string,
  errors: FieldErrors,
): string => {
  const error = errors[fieldName] || {};

  return error.message;
};

/**
 * Form to capture additional information required to complete an application.
 * Corresponds to Additional information (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A12662&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const AdditionalInformation = ({
  applicationId,
  control,
  errors,
  setValue,
  watch,
  telematicsInfo,
}: IAdditionalInfoProps) => {
  const { capture } = useAnalytics();
  const classes = useStyles();
  const dispatch = useDispatch();
  useWatch({ name: ['lossRunFiles', 'driverList'] });
  const getFeatureValue = useFeatureFlag();
  const isQuoteWithoutTelematicsEnabled = getFeatureValue(
    Feature.QUOTE_WITHOUT_TELEMATICS,
    false,
  );
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });
  const application = useSelector(applicationSelector);
  const { activeApplication, uploadedFiles } = application;
  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [drawerVisibility, setDrawerVisibility] =
    React.useState<boolean>(false);
  const [openDriversListDialog, setOpenDriversListDialog] =
    React.useState<boolean>(false);
  const [applicationUSState, setApplicationUSState] = React.useState<
    string | undefined
  >();
  const values = watch(['commodities', 'commoditiesComment', 'lossRunFiles']);
  const hasSelectedCommodities = !!(
    values.commodities &&
    values.commodities.filter((record: string) => !!record).length
  );

  const fetchUSState = React.useCallback(
    (dotNumber: number) => {
      dispatch(fetchDOTInformation({ dotNumber })).then((response) => {
        if (fetchDOTInformation.fulfilled.match(response)) {
          setApplicationUSState(response.payload.usState);
        }
      });
    },
    [dispatch],
  );

  React.useEffect(() => {
    capture(ADDITIONAL_INFO_PAGE_VIEW, {
      applicationId,
    });
  }, [applicationId, capture]);

  const targetPriceCoveragesList = [
    {
      coverageType: CoverageType.CoverageAutoLiability,
      label: 'Auto Liability',
      shortName: 'AL',
    },
  ];
  const totalVehicles =
    application?.activeApplication?.indicationForm?.operationsForm
      ?.equipmentList.info?.length || 0;

  const handleLossRunFileUpload = (files: any) => {
    setUploading(true);

    files.forEach((file: any) => {
      dispatch(
        uploadApplicationFiles(file, {
          type: FileType.FileTypeLossRun,
          destinationGroup: FileDestinationGroup.FileDestinationGroupQuoting,
        }),
      );
    });
  };

  const handleLossRunFileUploadCancel = (progress: FileUploadProgress) => {
    dispatch(cancelApplicationFilesUpload(progress));
  };

  const handleLossRunFileUploadRetry = (progress: FileUploadProgress) => {
    dispatch(retryApplicationFilesUpload(progress));
  };

  const handleLossRunFileRemove = (file?: FileUploadProgress) => {
    if (file && file.handle && uploadedFiles[file.handle]) {
      // Remove by filename
      dispatch(removeApplicationFileUpload(file));
    } else if (file && file.handle) {
      const newValue = values.lossRunFiles.filter(
        (record: FileMetadata) => record.handle !== file.handle,
      );
      setValue('lossRunFiles', newValue);
    } else if (!file) {
      // Remove all
      dispatch(resetApplicationFileUpload());
      setValue('lossRunFiles', []);
    } else {
      // Invalid case - do nothing
    }
  };

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) return;

    dispatch(downloadApplicationFile(handleId));
  };

  const handleCopyConnectionLink = (url: string) => {
    clipboard.copy(url);
  };

  const CommodityLabelList = Object.keys(
    ApplicationHelper.AdditionalInformationCommodityLabel,
  );

  React.useEffect(() => {
    if (!isUploading) return;

    const uploadedFilesValues = Object.values(uploadedFiles);
    const newFiles: any = [];
    uploadedFilesValues.forEach((record) => {
      if (record.status === 'succeeded') {
        newFiles.push(record);
        dispatch(removeApplicationFileUpload(record));
      }
    });
    setValue('lossRunFiles', [...newFiles, ...values.lossRunFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUploading, uploadedFiles, setValue]);

  React.useEffect(() => {
    if (values?.commodities?.length === 0) {
      setValue('commoditiesComment', undefined);
    }
  }, [values?.commodities, setValue]);

  React.useEffect(() => {
    if (activeApplication?.summary?.dotNumber) {
      fetchUSState(activeApplication?.summary?.dotNumber);
    }
  }, [fetchUSState, activeApplication?.summary?.dotNumber]);

  const lossRunUploadProps = {
    value: Object.values(uploadedFiles).concat(values.lossRunFiles ?? []),
    onChange: handleLossRunFileUpload,
    onRemove: handleLossRunFileRemove,
    onRetry: handleLossRunFileUploadRetry,
    onCancel: handleLossRunFileUploadCancel,
    onDownload: (file: FileUploadProgress) => handleFileDownload(file.handle),
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h4" fontWeight="fontWeightBold" mb={2}>
          Additional information
        </Typography>
        <Typography
          variant="h5"
          color="textSecondary"
          fontWeight="fontWeightRegular"
          mb={1}
        >
          To finalize your application and get a quote, we need you to complete
          a few tasks
        </Typography>
      </Grid>

      <Switch>
        <Switch.Match
          when={getFeatureValue(Feature.TELEMATICS_CONSENT_REVAMP, false)}
        >
          <ConnectEld
            errors={errors}
            applicationId={applicationId}
            telematicsInfo={telematicsInfo}
            lossRunUploadProps={lossRunUploadProps}
            onDriversListDiaglogOpen={() => setOpenDriversListDialog(true)}
          />
        </Switch.Match>

        <Switch.Match
          when={
            getFeatureValue(Feature.FLEET_TYPE, 'fleet') !==
            'wholesale-non-fleet'
          }
        >
          <Grid
            item
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.inputLabel}>
                Connect ELD/Telematics data so the insured may be eligible for
                up to 20% discount
              </InputLabel>
              <FormHelperText>
                The application won&apos;t be processed until connected
              </FormHelperText>
            </Grid>
            <Grid item xs={7}>
              {telematicsInfo?.link ? (
                <Box
                  flexDirection="column"
                  display="flex"
                  alignItems="flex-start"
                >
                  <Box
                    className={classes.telematicsInfoCard}
                    onClick={() => setDrawerVisibility(true)}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1" color="secondary">
                          {telematicsInfo?.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textHint"
                          component="p"
                        >
                          {telematicsInfo?.email}
                        </Typography>

                        <Link
                          style={{ cursor: 'pointer' }}
                          underline={clipboard.copied ? 'none' : 'hover'}
                          onClick={(e) => {
                            e.stopPropagation();

                            let link = telematicsInfo.link;
                            const host = import.meta.env.VITE_WEB_URL;
                            // Adding this validation in order to support older format of link
                            // Removing it after we successfully migrate to link including Schema and host.
                            if (!link.includes(host)) {
                              link = `${host}/${link}`;
                            }
                            handleCopyConnectionLink(link);
                          }}
                        >
                          {clipboard.copied ? (
                            <Typography variant="caption" color="success.main">
                              Copied!
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="primary">
                              Copy Connection Link
                            </Typography>
                          )}
                        </Link>
                      </Grid>
                      <Grid item display="flex" justifyContent="center">
                        <img src={IconEdit} alt="Edit" width={16} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Chip label="Link Generated" color="warning" />
                </Box>
              ) : (
                <Controller
                  control={control}
                  name="telematicsInfo"
                  rules={
                    isQuoteWithoutTelematicsEnabled
                      ? {}
                      : { required: 'Please generate the telematics link' }
                  }
                  render={() => {
                    return (
                      <>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() => setDrawerVisibility(true)}
                        >
                          Generate Link
                        </Button>
                        {!!errors?.telematicsInfo && (
                          <FormHelperText error>
                            {errors?.telematicsInfo?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
        </Switch.Match>
      </Switch>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="coverage-input" className={classes.inputLabel}>
              Drivers list
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            control={control}
            name="driverList"
            rules={{
              required: 'Please upload a list of drivers',
              validate: {
                totalGreaterThanZero: (value) => {
                  const summaryValues = ApplicationHelper.getDriversSummary(
                    value?.drivers,
                  );
                  return (
                    summaryValues.total > 0 ||
                    'Total number of drivers must be greater than 0'
                  );
                },
              },
            }}
            render={(props) => {
              const summaryValues = ApplicationHelper.getDriversSummary(
                props.value?.drivers,
              );
              const summaryRows = [
                {
                  label: 'Drivers aged 18-30 years',
                  value: summaryValues['18-30'],
                },
                {
                  label: 'Drivers aged 31-50 years',
                  value: summaryValues['31-50'],
                },
                {
                  label: 'Drivers aged 51+ years',
                  value: summaryValues['51+'],
                },
                {
                  label: 'Total # of drivers',
                  value: summaryValues.total,
                },
              ];

              return (
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    {(
                      props.value?.flatfileMetadata ||
                      props.value?.implerMetadata
                    )?.fileMetadata?.name ? (
                      <AttachmentView
                        files={[
                          {
                            filename:
                              (
                                props.value?.flatfileMetadata ||
                                props.value?.implerMetadata
                              )?.fileMetadata?.name || 'Drivers List.xlsx',
                          },
                        ]}
                        onRemove={() => {
                          props.onChange(null);
                        }}
                        onDownload={() => {
                          handleFileDownload(
                            (
                              props.value.flatfileMetadata ||
                              props.value.implerMetadata
                            ).fileMetadata.handle,
                          );
                        }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => setOpenDriversListDialog(true)}
                          startIcon={
                            <img src={IconFileUpload} alt="File Upload" />
                          }
                        >
                          Upload
                        </Button>
                      </>
                    )}
                    <DriversListDialog
                      {...props}
                      value={props.value}
                      onChange={(data) => {
                        props.onChange(data);
                      }}
                      usState={applicationUSState}
                      open={openDriversListDialog}
                      onClose={() => setOpenDriversListDialog(false)}
                      onUploadComplete={() => {
                        setOpenDriversListDialog(false);
                      }}
                      onStart={() => {
                        setOpenDriversListDialog(false);
                      }}
                    />
                  </Grid>
                  {props.value?.drivers ? (
                    <Grid item>
                      <div className={classes.summaryContainer}>
                        <SummaryView
                          title="Document Summary"
                          rows={summaryRows}
                        />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              );
            }}
          />
          {!!errors.driverList && (
            <FormHelperText error>
              {getHelperText('driverList', errors)}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={5}>
            <InputLabel
              htmlFor="numOwnerOperatorUnits-input"
              className={classes.inputLabel}
            >
              How many units in the fleet are owner-operators?
            </InputLabel>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="numOwnerOperatorUnits"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please enter the number of units',
                max: {
                  value: totalVehicles,
                  message:
                    "Owner-operator units shouldn't exceed uploaded equipment count",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter a valid number',
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControlInput}>
                  <InputNumeric
                    id="numOwnerOperatorUnits-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(event) => {
                      if (event.target.value && isNaN(+event.target.value)) {
                        return;
                      }

                      props.onChange(+event.target.value);
                    }}
                    error={!!errors.numOwnerOperatorUnits}
                  />
                  {!!errors.numOwnerOperatorUnits && (
                    <FormHelperText error>
                      {getHelperText('numOwnerOperatorUnits', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {applicationUSState === 'CA' && (
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={5}>
              <InputLabel
                htmlFor="percentageOfSubhaul-input"
                className={classes.inputLabel}
              >
                What % of your operations is subhaul?
              </InputLabel>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="percentageOfSubhaul"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please enter the percentage of subhaul operations',
                  max: {
                    value: 100,
                    message: 'Should not be greater than 100%',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid number',
                  },
                }}
                render={(props) => (
                  <FormControl className={classes.formControlInput}>
                    <InputNumeric
                      id="percentageOfSubhaul-input"
                      placeholder="0"
                      decimalScale={2}
                      value={props.value}
                      suffix="%"
                      onChange={(event) => {
                        if (event.target.value && isNaN(+event.target.value)) {
                          return;
                        }

                        props.onChange(+event.target.value);
                      }}
                      error={!!errors.percentageOfSubhaul}
                    />
                    {!!errors.percentageOfSubhaul && (
                      <FormHelperText error>
                        {getHelperText('percentageOfSubhaul', errors)}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="coverage-input" className={classes.inputLabel}>
              Select if any of these operations or commodities apply
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <FormGroup
            aria-label="commodities"
            className={classes.formControlTextarea}
          >
            <Controller
              name={'commodities'}
              defaultValue={[]}
              control={control}
              render={(props) => (
                <>
                  {CommodityLabelList.map((commodityItem: string) => (
                    <FormControl fullWidth key={commodityItem}>
                      <CheckboxRow
                        className={classes.checkbox}
                        label={ApplicationHelper.getAdditionInformationCommodityLabel(
                          commodityItem,
                        )}
                        checked={props.value.includes(commodityItem)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            props.onChange([...props.value, commodityItem]);
                          } else {
                            props.onChange(
                              props.value.filter(
                                (record: string) => record !== commodityItem,
                              ),
                            );
                          }
                        }}
                      />
                    </FormControl>
                  ))}
                </>
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {hasSelectedCommodities && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel
                htmlFor="commoditiesComment-input"
                className={classes.inputLabel}
              >
                Please share additional information here
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="commoditiesComment"
              defaultValue=""
              control={control}
              rules={{
                required: false,
              }}
              render={(props) => (
                <FormControl className={classes.formControlTextarea}>
                  <OutlinedInput
                    id="commoditiesComment-input"
                    multiline
                    rows={3}
                    placeholder="Write your additional information here..."
                    value={props.value}
                    onChange={props.onChange}
                    error={!!errors.commoditiesComment}
                  />
                  {!!errors.commoditiesComment && (
                    <FormHelperText error>
                      {getHelperText('commoditiesComment', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      )}

      <Grid item>
        <Divider />
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <InputLabel
            htmlFor="lossRunFiles-input"
            className={classes.inputLabel}
          >
            Loss Runs
          </InputLabel>
          <FormHelperText>Upload loss runs for past 5 years</FormHelperText>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="lossRunFiles"
            defaultValue={[]}
            control={control}
            rules={{
              validate: {
                required: (value) =>
                  !!value.length || 'Please upload at least one file',
              },
            }}
            render={() => {
              return (
                <>
                  <FileUpload
                    key="loss-run-file-upload"
                    {...lossRunUploadProps}
                  />
                  {!!errors.lossRunFiles && (
                    <FormHelperText error>
                      {getHelperText('lossRunFiles', errors)}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel
              htmlFor="largeLossComment-input"
              className={classes.inputLabel}
            >
              Large losses
            </InputLabel>
            <FormHelperText>
              Please provide additional information for any single loss
              occurrences greater than{' '}
              {totalVehicles > 20 ? '$50,000' : '$25,000'}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="largeLossComment"
            defaultValue=""
            control={control}
            rules={{
              required: false,
            }}
            render={(props) => (
              <FormControl className={classes.formControlTextarea}>
                <OutlinedInput
                  id="largeLossComment-input"
                  multiline
                  rows={3}
                  placeholder="Write your comments here..."
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.largeLossComment}
                />
                {!!errors.largeLossComment && (
                  <FormHelperText error>
                    {getHelperText('largeLossComment', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel
              htmlFor="incumbent-input"
              className={classes.inputLabel}
            >
              Do you currently control AL for this account?
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="coverageMetadata.additionalIncumbentInfo.isALIncumbent"
            defaultValue={undefined}
            control={control}
            rules={{
              validate: {
                required: (value?: boolean) => {
                  if (value === undefined) {
                    return 'Please indicate if you control AL for this account';
                  }

                  return true;
                },
              },
            }}
            render={({ onChange, value }) => {
              return (
                <FormControl>
                  <RadioGroup
                    aria-label="coverage"
                    value={`${value}`}
                    onChange={(e) => {
                      onChange(e.target.value === 'true');
                    }}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                  {!!errors.coverageMetadata?.additionalIncumbentInfo
                    .isALIncumbent && (
                    <FormHelperText error>
                      {
                        errors.coverageMetadata?.additionalIncumbentInfo
                          .isALIncumbent.message
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>

      {targetPriceCoveragesList.map((coverageInfo, index) => {
        const defaultValue: TargetPrice = {
          coverageType: coverageInfo.coverageType,
          totalPremium: undefined,
        };
        return (
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="flex-start"
            spacing={3}
            key={`target-price-${index}`}
          >
            <Grid item xs={5}>
              <Box paddingTop="10px">
                <InputLabel
                  htmlFor={`target-price-input${index}`}
                  className={classes.inputLabel}
                >
                  {`Total Target ${coverageInfo.shortName} Price`}
                  <FormHelperText>
                    {`Please provide total premium for ${coverageInfo.label} for this account`}
                  </FormHelperText>
                </InputLabel>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name={`targetPrices[${index}]`}
                defaultValue={defaultValue}
                control={control}
                rules={{
                  validate: {
                    isNumber: (value: TargetPrice) => {
                      if (
                        value.totalPremium !== undefined &&
                        isNaN(value.totalPremium)
                      ) {
                        return 'Please enter a valid number';
                      }
                      return true;
                    },
                  },
                }}
                render={({ onChange, value }) => {
                  return (
                    <FormControl className={classes.formControlInput}>
                      <InputNumeric
                        id={`target-price-input${index}`}
                        placeholder="$ 0"
                        prefix="$"
                        value={value.totalPremium}
                        onChange={(e) => {
                          const targetPriceObj: TargetPrice = {
                            coverageType: coverageInfo.coverageType,
                            totalPremium: +e.target.value,
                          };
                          if (targetPriceObj.totalPremium === 0) {
                            targetPriceObj.totalPremium = undefined;
                          }
                          onChange(targetPriceObj);
                        }}
                        error={!!errors.targetPrices}
                      />
                      {!!errors.targetPrices && (
                        <FormHelperText error>
                          {errors.targetPrices?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        );
      })}

      <Grid item>
        <Divider />
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel
              htmlFor="overallComment-input"
              className={classes.inputLabel}
            >
              Additional information
            </InputLabel>
            <FormHelperText>
              Anything else you&apos;d like your underwriter to know?
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="overallComment"
            defaultValue=""
            control={control}
            rules={{
              required: false,
            }}
            render={(props) => (
              <FormControl className={classes.formControlTextarea}>
                <OutlinedInput
                  id="overallComment-input"
                  multiline
                  rows={3}
                  placeholder="Expiring/target pricing, sales situation, etc."
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.overallComment}
                />
                {!!errors.overallComment && (
                  <FormHelperText error>
                    {getHelperText('overallComment', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <ConnectionDrawer
        open={drawerVisibility}
        onClose={() => setDrawerVisibility(false)}
        applicationId={applicationId}
        telematicsInfo={telematicsInfo}
      />
    </Grid>
  );
};

const AdditionalInformationContainer = (props: any) => {
  const methods = useFormContext();

  return <AdditionalInformation {...props} {...methods} />;
};

export default AdditionalInformationContainer;
