import { createColumnHelper } from '@tanstack/react-table';
import { Chip, getFormattedDate, Show } from '@nirvana/ui-kit';

type DriverData = {
  cdlNumber: string;
  dateOfBirth: string;
  dateOfHire: string;
  dlNumber: string;
  isExcluded: boolean;
  isOutOfState: boolean;
  isOwner: boolean;
  name: string;
  state: string;
  yearsOfExp: number;
};

export const mockDriverData: DriverData[] = [
  {
    cdlNumber: '054117921',
    dateOfBirth: '1974-01-10',
    dateOfHire: '2021-10-27',
    dlNumber: '054117921',
    isExcluded: false,
    isOutOfState: false,
    isOwner: true,
    name: 'KHALIFF JAFFARA',
    state: 'GA',
    yearsOfExp: 29,
  },
  {
    cdlNumber: '051876762',
    dateOfBirth: '1963-01-01',
    dateOfHire: '2023-09-01',
    dlNumber: '051876762',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'AWIL AHMED',
    state: 'GA',
    yearsOfExp: 2,
  },
  {
    cdlNumber: '057899319',
    dateOfBirth: '1990-02-25',
    dateOfHire: '2022-09-22',
    dlNumber: '057899319',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'DEREJE WEYO',
    state: 'GA',
    yearsOfExp: 3,
  },
  {
    cdlNumber: '059751250',
    dateOfBirth: '1969-05-05',
    dateOfHire: '2023-10-27',
    dlNumber: '059751250',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'BEREKET TADEGE',
    state: 'GA',
    yearsOfExp: 9,
  },
  {
    cdlNumber: '051519455',
    dateOfBirth: '1980-04-19',
    dateOfHire: '2024-03-25',
    dlNumber: '051519455',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'MEBRATU GETE',
    state: 'GA',
    yearsOfExp: 9,
  },
  {
    cdlNumber: '049966924',
    dateOfBirth: '1979-10-15',
    dateOfHire: '2023-10-27',
    dlNumber: '049966924',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'MOHAMED SHERIFF',
    state: 'GA',
    yearsOfExp: 4,
  },
  {
    cdlNumber: '059696420',
    dateOfBirth: '1976-05-15',
    dateOfHire: '2024-01-10',
    dlNumber: '059696420',
    isExcluded: false,
    isOutOfState: false,
    isOwner: false,
    name: 'OSMAN JAFFARA',
    state: 'GA',
    yearsOfExp: 16,
  },
];

const columnHelper = createColumnHelper<DriverData>();

export const driverColumns = [
  columnHelper.accessor('name', {
    header: 'Driver Name',
    size: 240,
    cell: ({ getValue, row }) => (
      <p>
        {getValue()}{' '}
        <Show when={row.original.isOwner}>
          <Chip label="Owner" color="info" />
        </Show>
      </p>
    ),
  }),
  columnHelper.accessor('cdlNumber', { header: 'CDL Number' }),

  columnHelper.accessor('state', { header: 'License State', size: 60 }),

  columnHelper.accessor('dateOfBirth', {
    header: 'Date of Birth',
    cell: ({ getValue }) => getFormattedDate(getValue()),
  }),

  columnHelper.accessor('dateOfHire', {
    header: 'Date of Hire',
    cell: ({ getValue }) => getFormattedDate(getValue()),
  }),

  columnHelper.accessor('yearsOfExp', { header: 'Years of CDL', size: 80 }),
];
