/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana IB API
 * Nirvana IB APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface Coverage
 */
export interface Coverage {
  /**
   *
   * @type {string}
   * @memberof Coverage
   */
  coverageName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Coverage
   */
  limits: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Coverage
   */
  deductible: number;
}
/**
 *
 * @export
 * @interface CreateEndorsementRequestBody
 */
export interface CreateEndorsementRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateEndorsementRequestBody
   */
  createdBy: string;
  /**
   *
   * @type {ProgramType}
   * @memberof CreateEndorsementRequestBody
   */
  programType: ProgramType;
}
/**
 *
 * @export
 * @interface CreateEndorsementResponse
 */
export interface CreateEndorsementResponse {
  /**
   *
   * @type {string}
   * @memberof CreateEndorsementResponse
   */
  endorsementRequestID: string;
}
/**
 *
 * @export
 * @interface Driver
 */
export interface Driver {
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  lastName: string;
  /**
   *
   * @type {boolean}
   * @memberof Driver
   */
  isOwner: boolean;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  licenseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  licenseState: string;
  /**
   *
   * @type {boolean}
   * @memberof Driver
   */
  isOutOfState: boolean;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  dateOfHire: string;
  /**
   *
   * @type {number}
   * @memberof Driver
   */
  yearsOfExperience: number;
}
/**
 *
 * @export
 * @interface GetInsuranceBundleListRecord
 */
export interface GetInsuranceBundleListRecord {
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  bundleID: string;
  /**
   *
   * @type {Policy}
   * @memberof GetInsuranceBundleListRecord
   */
  policy: Policy;
  /**
   *
   * @type {Insured}
   * @memberof GetInsuranceBundleListRecord
   */
  primaryInsured: Insured;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  expirationDate: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  status: GetInsuranceBundleListRecordStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  cta: GetInsuranceBundleListRecordCtaEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GetInsuranceBundleListRecordStatusEnum {
  Active = 'Active',
  Expired = 'Expired',
  UpForRenewal = 'UpForRenewal',
  Renewed = 'Renewed',
  PendingCancellation = 'PendingCancellation',
  Cancelled = 'Cancelled',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInsuranceBundleListRecordCtaEnum {
  ActionNeeded = 'Action Needed',
  NoActionNeeded = 'No Action Needed',
}

/**
 *
 * @export
 * @interface GetInsuranceBundleResponse
 */
export interface GetInsuranceBundleResponse {
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  policyNumber: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  companyName: string;
  /**
   *
   * @type {PackageName}
   * @memberof GetInsuranceBundleResponse
   */
  packageType: PackageName;
  /**
   *
   * @type {number}
   * @memberof GetInsuranceBundleResponse
   */
  totalPremium: number;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  effectiveDateTo: string;
  /**
   *
   * @type {PolicyStatus}
   * @memberof GetInsuranceBundleResponse
   */
  status: PolicyStatus;
  /**
   *
   * @type {Array<Coverage>}
   * @memberof GetInsuranceBundleResponse
   */
  coverages: Array<Coverage>;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof GetInsuranceBundleResponse
   */
  vehicles: Array<Vehicle>;
  /**
   *
   * @type {Array<Driver>}
   * @memberof GetInsuranceBundleResponse
   */
  drivers: Array<Driver>;
}
/**
 *
 * @export
 * @interface Insured
 */
export interface Insured {
  /**
   *
   * @type {string}
   * @memberof Insured
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Insured
   */
  DOTNumber: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PackageName {
  Basic = 'Basic',
  Standard = 'Standard',
  Complete = 'Complete',
}

/**
 *
 * @export
 * @interface Policy
 */
export interface Policy {
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  policyNumber: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PolicyStatus {
  Active = 'Active',
  Expired = 'Expired',
  UpForRenewal = 'Up for Renewal',
  Renewed = 'Renewed',
  PendingCancellation = 'Pending Cancellation',
  Cancelled = 'Cancelled',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ProgramType {
  ProgramTypeFleet = 'ProgramTypeFleet',
  ProgramTypeNonFleetCanopiusNrb = 'ProgramTypeNonFleetCanopiusNRB',
  ProgramTypeNonFleetAdmitted = 'ProgramTypeNonFleetAdmitted',
  ProgramTypeInvalid = 'ProgramTypeInvalid',
}

/**
 *
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  model: string;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  year: number;
  /**
   *
   * @type {VehicleType}
   * @memberof Vehicle
   */
  vehicleType: VehicleType;
  /**
   *
   * @type {VehicleClass}
   * @memberof Vehicle
   */
  vehicleClass: VehicleClass;
  /**
   *
   * @type {WeightClass}
   * @memberof Vehicle
   */
  weightClass: WeightClass;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  statedValue?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum VehicleClass {
  Agricultural = 'Agricultural',
  AutoHauler = 'AutoHauler',
  BulkCommodity = 'BulkCommodity',
  DryFreight = 'DryFreight',
  Dump = 'Dump',
  Flatbed = 'Flatbed',
  FrontLoader = 'FrontLoader',
  Garbage = 'Garbage',
  Livestock = 'Livestock',
  Logging = 'Logging',
  Lowboy = 'Lowboy',
  PickupTruck = 'PickupTruck',
  Pole = 'Pole',
  RagTop = 'RagTop',
  Reefer = 'Reefer',
  Stake = 'Stake',
  Straight = 'Straight',
  Tank = 'Tank',
  Tilt = 'Tilt',
  TruckTractor = 'TruckTractor',
  Utility = 'Utility',
  Unidentified = 'Unidentified',
  Other = 'Other',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum VehicleType {
  Truck = 'Truck',
  Tractor = 'Tractor',
  Trailer = 'Trailer',
  Pickup = 'Pickup',
}

/**
 * Below is the class conversion to pound for you to select the class A = < 3,000 pounds B = 3,001 to 3,500 pounds C = 3,501 to 4,000 pounds D = 4,001 to 4,500 pounds E = 4,501 to 5,000 pounds F = 5,001 to 5,500 pounds G = 5,501 to 6,000 pounds H = 6,001 to 10,000 pounds 3 = 10,001 to 14,000 pounds 4 = 14,001 to 16,000 pounds 5 = 16,001 to 19,500 pounds 6 = 19,501 to 26,000 pounds 7 = 26,001 to 33,000 pounds 8 = \"> 33,000 pounds\"
 * @export
 * @enum {string}
 */

export enum WeightClass {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7',
  _8 = '8',
}

/**
 * EndorsementappIntakeApi - axios parameter creator
 * @export
 */
export const EndorsementappIntakeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEndorsementRequest: async (
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('createEndorsementRequest', 'bundleID', bundleID);
      // verify required parameter 'createEndorsementRequestBody' is not null or undefined
      assertParamExists(
        'createEndorsementRequest',
        'createEndorsementRequestBody',
        createEndorsementRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request`.replace(
          `{${'bundleID'}}`,
          encodeURIComponent(String(bundleID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEndorsementRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEndorsementRequests: async (
      bundleID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getAllEndorsementRequests', 'bundleID', bundleID);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/list`.replace(
          `{${'bundleID'}}`,
          encodeURIComponent(String(bundleID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestCoverages: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getEndorsementRequestCoverages', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestCoverages',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/coverages`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestDrivers: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getEndorsementRequestDrivers', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestDrivers',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/drivers`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestEquipments: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestEquipments',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestEquipments',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/equipments`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestMiscellaneous: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestMiscellaneous',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestMiscellaneous',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/miscellaneous`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestCoverages: async (
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestCoverages',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestCoverages',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateEndorsementRequestCoverages', 'body', body);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/coverages`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestDrivers: async (
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestDrivers',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestDrivers',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateEndorsementRequestDrivers', 'body', body);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/drivers`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestEquipments: async (
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestEquipments',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestEquipments',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateEndorsementRequestEquipments', 'body', body);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/equipments`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestMiscellaneous: async (
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestMiscellaneous',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestMiscellaneous',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateEndorsementRequestMiscellaneous', 'body', body);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/miscellaneous`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EndorsementappIntakeApi - functional programming interface
 * @export
 */
export const EndorsementappIntakeApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    EndorsementappIntakeApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEndorsementRequest(
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateEndorsementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createEndorsementRequest(
          bundleID,
          createEndorsementRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEndorsementRequests(
      bundleID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllEndorsementRequests(
          bundleID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EndorsementappIntakeApi - factory interface
 * @export
 */
export const EndorsementappIntakeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EndorsementappIntakeApiFp(configuration);
  return {
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEndorsementRequest(
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options?: any,
    ): AxiosPromise<CreateEndorsementResponse> {
      return localVarFp
        .createEndorsementRequest(
          bundleID,
          createEndorsementRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEndorsementRequests(
      bundleID: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .getAllEndorsementRequests(bundleID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .getEndorsementRequestCoverages(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .getEndorsementRequestDrivers(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .getEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .getEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      body: object,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          body,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EndorsementappIntakeApi - object-oriented interface
 * @export
 * @class EndorsementappIntakeApi
 * @extends {BaseAPI}
 */
export class EndorsementappIntakeApi extends BaseAPI {
  /**
   * Create a new endorsement request based on the Insurance Bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public createEndorsementRequest(
    bundleID: string,
    createEndorsementRequestBody: CreateEndorsementRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .createEndorsementRequest(bundleID, createEndorsementRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all Endorsement Requests for a bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getAllEndorsementRequests(
    bundleID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getAllEndorsementRequests(bundleID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch coverage details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestCoverages(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestCoverages(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch driver details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestDrivers(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestDrivers(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch equipment details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestEquipments(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestEquipments(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch miscellaneous details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestMiscellaneous(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestMiscellaneous(
        bundleID,
        endorsementRequestID,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update coverage details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestCoverages(
    bundleID: string,
    endorsementRequestID: string,
    body: object,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestCoverages(
        bundleID,
        endorsementRequestID,
        body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update driver details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestDrivers(
    bundleID: string,
    endorsementRequestID: string,
    body: object,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestDrivers(
        bundleID,
        endorsementRequestID,
        body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update equipment details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestEquipments(
    bundleID: string,
    endorsementRequestID: string,
    body: object,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestEquipments(
        bundleID,
        endorsementRequestID,
        body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update miscellaneous details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestMiscellaneous(
    bundleID: string,
    endorsementRequestID: string,
    body: object,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestMiscellaneous(
        bundleID,
        endorsementRequestID,
        body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceBundleApi - axios parameter creator
 * @export
 */
export const InsuranceBundleApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundle: async (
      bundleID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getInsuranceBundle', 'bundleID', bundleID);
      const localVarPath = `/insurance-bundle/{bundleID}`.replace(
        `{${'bundleID'}}`,
        encodeURIComponent(String(bundleID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundleList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/insurance-bundle/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceBundleApi - functional programming interface
 * @export
 */
export const InsuranceBundleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InsuranceBundleApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceBundle(
      bundleID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInsuranceBundleResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceBundle(bundleID, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceBundleList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetInsuranceBundleListRecord>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceBundleList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * InsuranceBundleApi - factory interface
 * @export
 */
export const InsuranceBundleApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InsuranceBundleApiFp(configuration);
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundle(
      bundleID: string,
      options?: any,
    ): AxiosPromise<GetInsuranceBundleResponse> {
      return localVarFp
        .getInsuranceBundle(bundleID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundleList(
      options?: any,
    ): AxiosPromise<Array<GetInsuranceBundleListRecord>> {
      return localVarFp
        .getInsuranceBundleList(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceBundleApi - object-oriented interface
 * @export
 * @class InsuranceBundleApi
 * @extends {BaseAPI}
 */
export class InsuranceBundleApi extends BaseAPI {
  /**
   * Retrieves detailed information for a specific insurance bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceBundleApi
   */
  public getInsuranceBundle(bundleID: string, options?: AxiosRequestConfig) {
    return InsuranceBundleApiFp(this.configuration)
      .getInsuranceBundle(bundleID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves a list of all available insurance bundles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceBundleApi
   */
  public getInsuranceBundleList(options?: AxiosRequestConfig) {
    return InsuranceBundleApiFp(this.configuration)
      .getInsuranceBundleList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
